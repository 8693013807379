import {IS_DEVELOPMENT} from '@/common/config'

export function loadScripts() {
  const searchParams = new URLSearchParams(window.location.search)
  const isStoryblok = !!searchParams.get('_storyblok')

  if (!IS_DEVELOPMENT && !isStoryblok) {
    function loadTagManager() {
      if (!window._mtm) {
        window._axcb = window._axcb || []
        window._axcb.push((axeptio) => {
          function handleChoices(choices) {
            if (typeof choices === 'object') {
              document.querySelectorAll('[data-hide-on-vendor-consent]').forEach((el) => {
                const vendor = el.getAttribute('data-hide-on-vendor-consent')
                el.style.display = choices[vendor] ? 'none' : 'inherit'
              })
              document.querySelectorAll('[data-requires-vendor-consent]').forEach((el) => {
                const vendor = el.getAttribute('data-requires-vendor-consent')
                if (choices[vendor]) {
                  el.setAttribute('src', el.getAttribute('data-src'))
                }
              })
            }
          }

          axeptio.on('cookies:complete', function (choices) {
            if (!window.axeptioIsReady) {
              window.axeptioIsReady = true
              handleChoices(choices)
            }
          })

          axeptio.on('consent:saved', function (data) {
            const choices = data?.consent?.preferences?.vendors
            handleChoices(choices)
          })
        })

        var _mtm = (window._mtm = window._mtm || [])
        _mtm.push({'mtm.startTime': new Date().getTime(), event: 'mtm.Start'})
        var d = document,
          g = d.createElement('script'),
          s = d.getElementsByTagName('script')[0]
        g.async = true
        g.src = '/aT8Qm5ejKk/container_Yb5TZ50Z.js'
        s.parentNode.insertBefore(g, s)
      }
    }

    loadTagManager()
  }
}

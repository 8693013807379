const IS_DEVELOPMENT = [process.env.NODE_ENV, process.env.GATSBY_ENV].includes('development')

const STORYBLOK_TOKEN = 'u4IRp2BtLsrv1B3QoQWFQAtt'
const DEFAULT_LOCALE = 'fr'
const RESOLVE_RELATIONS = [
  'content-columns.testimonial',
  'featured-page.page',
  'features-slider-item.testimonial',
  'call-to-action.testimonial',
  'global-component.reference',
  'key-figures.testimonial',
  'page.category',
  'page.author',
  'team.members',
  'pages-list.categories',
]

const getPagePath = (full_slug) => {
  return full_slug === 'index' || full_slug === '/'
    ? '/'
    : `/${full_slug.replace('/index', '').replace(/^\/+/, '').replace(/\/+$/, '')}/`
        .replace('/http', 'http')
        .replace('*/', '*')
        .replace(':splat/', ':splat')
}

const getLayoutUuid = (story) =>
  story?.content?.layout || story?.content?.category?.content?.articlesLayout

const generateBreadcrumb = async ({story, stories, getStory, params}) => {
  if (story) {
    const fullSlug = story.full_slug.replace(/\/(index)?$/, '').split('/')
    const hasParent = fullSlug.length > 1

    if (hasParent) {
      const parentSlug = fullSlug.slice(0, -1).join('/') + '/'

      let parent
      if (stories) {
        const story = stories.find(({full_slug}) =>
          [parentSlug, parentSlug + 'index'].includes(full_slug)
        )
        if (story) {
          parent = {
            ...story,
            content: typeof story.content === 'string' ? JSON.parse(story.content) : story.content,
          }
        }
      } else if (getStory && params) {
        parent = await getStory(parentSlug, params).catch(
          async () => await getStory(parentSlug + 'index', params).catch(() => {})
        )
      }

      if (parent && typeof parent.content === 'object') {
        const parentBreadcrumb = await generateBreadcrumb({
          story: parent,
          stories,
          getStory,
          params,
        })

        return [
          ...(parentBreadcrumb || []),
          {
            name: parent.name,
            title: parent.content.title,
            full_slug: parent.full_slug.replace(/(\/)?(index)?$/, '/'),
          },
        ]
      }
    }
  }

  return undefined
}

function toKebabCase(string) {
  if (typeof string === 'string') {
    return string
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, '')
  }

  return ''
}

// eslint-disable-next-line no-warning-comments
// DO NOT TOUCH - Populate by prebuild.js
const LOCALES = ["en"]
const SITE_NAME = 'Witik'
const SITE_URL = 'https://www.witik.io'

module.exports = {
  DEFAULT_LOCALE,
  IS_DEVELOPMENT,
  LOCALES,
  RESOLVE_RELATIONS,
  SITE_NAME,
  SITE_URL,
  STORYBLOK_TOKEN,
  utils: {
    generateBreadcrumb,
    getLayoutUuid,
    getPagePath,
    toKebabCase,
  },
}
